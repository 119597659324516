body {
	min-height: 100vh !important;
	background-image: radial-gradient(circle farthest-corner at 10% 20%, #fff2d5 0%, #ffe0f2 100.7%) !important;
}

.horizontal-scroll-container::-webkit-scrollbar {
	display: none;
}

.horizontal-scroll-container {
	-ms-overflow-style: "none";
	overflow: "-moz-scrollbar-none";
}

.horizontal-scroll-container > * {
	user-select: none;
}

.horizontal-scroll-container > * > * {
	user-select: none;
}

.default-time-picker {
	background-color: whitesmoke;
	border-radius: 0.5rem;
	padding: 2% 4%;
}

.react-time-picker__wrapper {
	border: none;
}

.chakra-avatar__img {
	border-radius: 0.75rem !important;
}
